<template>
  <div>
    <div>
      <Navbar
        :class="
          $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
            ? 'primary'
            : 'purple-gradient'
        "
      />
      <UserNavbar />
    </div>
    <div class="body-container">
      <div v-if="loadBuffer <= 1">
        <v-row class="bg-purple-gradient">
          <v-col cols="12" align="center">
            <PhotoUpload
              :image-url="user['photo']"
              @uploadPictureFile="uploadPictureFile"
            />
          </v-col>
        </v-row>
        <v-container class="px-10 px-md-auto pb-md-0">
          <v-form ref="profileForm" v-model="valid" lazy-validation>
            <div class="text-h2 text-md-h1">Mi perfil</div>
            <!-- Provider Info. -->
            <div class="mt-5">
              <div class="text-h3 text-md-h2 font-weight-bold">
                Información general
              </div>

              <v-row class="mt-2">
                <v-col cols="12" md="6" class="py-0 py-md-1">
                  <div class="d-flex">
                    <span class="text-h3 font-weight-bold">Nombre legal*</span>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-1"
                          color="secondary"
                          dark
                          size="21"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>
                        El nombre legal (nombre y apellidos) se mostrará en los
                        contratos de los servicios contratados.
                      </span>
                    </v-tooltip>
                  </div>
                  <v-text-field
                    v-model="user['name']"
                    outlined
                    dense
                    placeholder="Nombre y apellidos"
                    type="text"
                    class="v-input--is-focused secondary--text rounded-lg"
                    maxlength="50"
                    counter="50"
                    :rules="nameRules"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0 py-md-1">
                  <span class="text-h3 font-weight-bold">
                    Nombre de preferencia*
                  </span>
                  <v-text-field
                    v-model="user['preferedName']"
                    outlined
                    dense
                    type="text"
                    class="v-input--is-focused secondary--text rounded-lg"
                    maxlength="50"
                    counter="50"
                    :rules="nameRules"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0 py-md-1">
                  <span class="text-h3 font-weight-bold">Correo*</span>
                  <v-text-field
                    v-model="user['email']"
                    disabled
                    outlined
                    dense
                    type="email"
                    class="v-input--is-focused secondary--text rounded-lg"
                    maxlength="100"
                    :rules="emailRules"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0 py-md-1">
                  <span class="text-h3 font-weight-bold">Teléfono*</span>
                  <v-text-field
                    v-model="user['phone']"
                    outlined
                    dense
                    type="tel"
                    class="v-input--is-focused secondary--text rounded-lg"
                    :counter="maxDigitsPhone"
                    :rules="phoneRules"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0 py-md-1">
                  <span class="text-h3 font-weight-bold">Dirección</span>
                  <v-text-field
                    v-model="user['address']"
                    outlined
                    dense
                    type="text"
                    class="v-input--is-focused secondary--text rounded-lg"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0 py-md-1">
                  <span class="text-h3 font-weight-bold">Código postal</span>
                  <v-text-field
                    v-model="user['zipCode']"
                    outlined
                    dense
                    class="v-input--is-focused secondary--text rounded-lg"
                    maxlength="5"
                    :counter="maxDigitsPostalCode"
                    :rules="optionalPostalCodeRules"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0 py-md-1">
                  <span class="text-h3 font-weight-bold">Estado*</span>
                  <v-autocomplete
                    v-model="user['state']"
                    :items="states"
                    auto-select-first
                    outlined
                    dense
                    color="secondary"
                    item-color="secondary"
                    no-data-text="Estado no encontrado"
                    :menu-props="{
                      bottom: true,
                      closeOnContentClick: true,
                      allowOverflow: true,
                      offsetY: true,
                      rounded: 'md',
                      transition: 'slide-y-transition',
                    }"
                    autocomplete="null"
                    :rules="stateRulesSelect"
                    class="v-input--is-focused secondary--text rounded-lg"
                    @change="userStateUpdated"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0 py-md-1">
                  <span class="text-h3 font-weight-bold">Ciudad*</span>
                  <v-autocomplete
                    v-model="user['city']"
                    :items="citySelect"
                    auto-select-first
                    outlined
                    dense
                    color="secondary"
                    item-color="secondary"
                    :no-data-text="
                      user['state']
                        ? 'Ciudad no encontrada'
                        : 'Seleccione un estado'
                    "
                    :menu-props="{
                      bottom: true,
                      closeOnContentClick: true,
                      allowOverflow: true,
                      offsetY: true,
                      rounded: 'md',
                      transition: 'slide-y-transition',
                    }"
                    autocomplete="null"
                    :rules="cityRulesSelect"
                    class="v-input--is-focused secondary--text rounded-lg"
                  />
                </v-col>
              </v-row>
            </div>

            <div class="mt-10 mb-12 d-flex flex-column">
              <div class="text-h3 font-weight-bold">Contraseña</div>
              <ModalPasswordUpdate />
            </div>

            <!-- Bank Account Info. -->
            <div class="mt-12 mb-8">
              <div class="text-h3 font-weight-bold">Descripción</div>
              <v-textarea
                v-model="user['description']"
                class="v-input--is-focused secondary--text rounded-lg"
                placeholder="Escribe sobre tí para que te conozcan los proveedores."
                rows="4"
                outlined
                dense
                auto-grow
                maxlength="255"
                counter="255"
                :rules="optionalTextAreaRules"
              />
            </div>

            <!-- Notifications -->
            <!-- <div class="mb-16">
              <div class="text-h5 font-weight-bold">Notificaciones</div>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-checkbox
                    id="emailNotificationCheck"
                    v-model="checkboxEmail"
                    color="secondary"
                    hide-details
                    label="Recibir notificaciones por correo"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-checkbox
                    id="quotationNotificationCheck"
                    v-model="checkboxQuotation"
                    color="secondary"
                    hide-details
                    label="Recibir notificación cuando acepten mis solicitudes"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-checkbox
                    id="emailNotificationCheck"
                    v-model="checkboxEmailNotification"
                    color="secondary"
                    hide-details
                    label="Recibir notificaciones por SMS"
                  >
                    <template v-slot:label> </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-checkbox
                    id="quotationNotificationCheck"
                    v-model="checkboxEachQuotation"
                    color="secondary"
                    hide-details
                    label="Recibir notificación por cada mensaje nuevo"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </div> -->
            <div class="mb-16 py-10 d-flex justify-center justify-sm-end">
              <Button
                text="Guardar cambios"
                aria-label="Guardar cambios"
                @event="submitInfo()"
              />
            </div>
            <!-- <div class="mb-6 d-flex text-center">
              <ModalEraseAccount :modal-info="user" />
            </div> -->
          </v-form>
        </v-container>
      </div>
      <v-container v-else>
        <InlineLoading />
      </v-container>
    </div>
    <ModalLoading :dialog="loading" />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/User/Shared/Navbar.vue";
import UserNavbar from "@/components/User/Shared/UserNavbar.vue";
import PhotoUpload from "@/components/Shared/PhotoUpload.vue";
import ModalPasswordUpdate from "@/components/Shared/ModalPasswordUpdate.vue";
import Button from "@/components/Shared/Button.vue";
import Footer from "@/components/Shared/Footer.vue";
// import ModalEraseAccount from "@/components/User/Profile/ModalEraseAccount.vue";
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";
import InlineLoading from "@/components/Shared/InlineLoading.vue";
import { mapState } from "vuex";
import { states, getCitiesByState } from "@/Utils/locations.js";
import {
  nameRules,
  stateRulesSelect,
  cityRulesSelect,
  emailRules,
  phoneRules,
  optionalPostalCodeRules,
  passwordRules,
  confirmPasswordRules,
  checkBoxRules,
  maxDigitsPhone,
  maxDigitsPostalCode,
  optionalTextAreaRules,
} from "@/Utils/rules";
export default {
  name: "ProfileUser",
  components: {
    Navbar,
    UserNavbar,
    PhotoUpload,
    ModalPasswordUpdate,
    Button,
    Footer,
    // ModalEraseAccount,
    ModalLoading,
    InlineLoading,
  },
  data: () => ({
    dialog: false,
    checkbox: true,
    valid: false,
    loading: false,
    btnColor: "primary",
    userDescription: "",
    loadBuffer: 2,
    // checkboxEmail: true,
    // checkboxEmailNotification: true,
    // checkboxQuotation: true,
    // checkboxEachQuotation: true,
    fileType: "",
    nameRules: nameRules,
    stateRulesSelect: stateRulesSelect,
    cityRulesSelect: cityRulesSelect,
    emailRules: emailRules,
    phoneRules: phoneRules,
    optionalPostalCodeRules: optionalPostalCodeRules,
    passwordRules: passwordRules,
    confirmPasswordRules: confirmPasswordRules,
    checkBoxRules: checkBoxRules,
    optionalTextAreaRules: optionalTextAreaRules,
    maxDigitsPhone: maxDigitsPhone,
    maxDigitsPostalCode: maxDigitsPostalCode,
    states: states,
  }),
  computed: mapState({
    user(state) {
      if (state.user) {
        return state.user;
      } else {
        return { photo: null };
      }
    },
    citySelect() {
      return getCitiesByState(this.user.state);
    },
  }),
  watch: {
    loadBuffer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.loadBuffer--;
          }, 800);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async submitInfo() {
      if (this.$refs.profileForm.validate()) {
        this.loading = true;
        await this.$store.dispatch("updateUserInfo", this.user);
        this.loading = false;
      }
    },
    async uploadPictureFile(file) {
      this.loading = true;
      await this.$store.dispatch("uploadUserPictureProfile", file);
      this.loading = false;
    },
    userStateUpdated() {
      this.user.city = "";
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-select__selection,
::v-deep .v-select__selection--comma,
::v-deep .v-select.v-text-field input {
  color: black !important;
}
.bg-purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 255) 255%
  );
  background-size: cover !important;
}
</style>
